@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-list {
  counter-reset: list-counter;
}
.custom-list.multi-item > li {
  counter-increment: list-counter;
}
.custom-list.multi-item > li::before {
  content: counter(list-counter);
}